@import './utilities.scss';

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
    margin: 0; 
    // font-family: Roboto, "Helvetica Neue", sans-serif; 
    font-family: "Roobert Regular";
    font-size: 16px;
}
.container { margin: 1%; }

@font-face {
    font-family: 'Roobert Light';
    src: url('https://caflerweb.azureedge.net/fonts/Roobert-Light.woff') format('woff');
}

@font-face {
    font-family: 'Roobert Medium';
    src: url('https://caflerweb.azureedge.net/fonts/Roobert-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Roobert Regular';
    src: url('https://caflerweb.azureedge.net/fonts/Roobert-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Roobert SemiBold';
    src: url('https://caflerweb.azureedge.net/fonts/Roobert-SemiBold.otf') format('woff');
}

