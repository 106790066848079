.flex-box {
  display: flex;
  gap: 8px;
  align-items: center;
}

.bold {
  font-family: "Roobert SemiBold";
}

.text-link {
  color: #180c2c;
  text-decoration-line: underline;
  cursor: pointer;
  font-family: "Roobert Regular";
}

.text-link:hover {
  color: #180c2c;
  font-family: "Roobert Medium";
  text-decoration-line: underline;
}

.text-link:active {
  color: #6c5695;
  font-family: "Roobert Regular";
  text-decoration-line: underline;

  img {
    filter: invert(38%) sepia(11%) saturate(1639%) hue-rotate(309deg)
      brightness(82%) contrast(94%);
  }
}

.max-col {
  max-width: 494px;
}

.sticky-header {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}

.line {
  border-bottom: 1px solid #c2c2c2;
}

.flex {
  display: flex;
}

.invalid {
  border: 1px solid red !important;
}

.error-message {
  color: #f0563c;
  font-size: 12px;
}

.rounded {
  border-radius: 6px;
}

.border-dashed {
  border: 1px dashed #c2c2c2;
}

.my-20 {
  margin: 20px 0;
}

.wrap-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle-1 {
  color: #180c2c;
  font-size: 14px;
  font-family: "Roobert SemiBold";
  line-height: 18px;
  font-size: 1rem;
}
